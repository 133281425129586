define("osf-components/components/registries/schema-block-renderer/editable/text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a+2HCzTz",
    "block": "{\"symbols\":[\"form\",\"@changeset\",\"@disabled\",\"@shouldShowMessages\",\"&attrs\",\"@uniqueID\",\"@schemaBlock\",\"@onInput\",\"@draftManager\",\"@node\"],\"statements\":[[5,\"registries/schema-block-renderer/editable/base\",[],[[\"@helpText\"],[[23,0,[\"schemaBlock\",\"helpText\"]]]],{\"statements\":[[0,\"\\n    \"],[5,\"form-controls\",[[13,5]],[[\"@changeset\",\"@disabled\",\"@shouldShowMessages\"],[[23,2,[]],[23,3,[]],[23,4,[]]]],{\"statements\":[[0,\"\\n        \"],[6,[23,1,[\"text\"]],[[12,\"class\",[29,[[28,\"local-class\",[\"schema-block-input\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/editable/text/styles\"]]]]]],[12,\"data-test-text-input\",\"\"]],[[\"@uniqueID\",\"@valuePath\",\"@onKeyUp\",\"@retrievalTitle\",\"@retrievalDate\",\"@retrievalVersion\",\"@readonly\",\"@title\",\"@nodeId\",\"@datetimeInitiated\",\"@datetimeUpdated\",\"@placeholder\"],[[23,6,[]],[23,7,[\"registrationResponseKey\"]],[23,8,[]],[23,7,[\"retrievalTitle\"]],[23,7,[\"retrievalDate\"]],[23,7,[\"retrievalVersion\"]],[23,7,[\"readonly\"]],[23,9,[\"node\",\"title\"]],[23,10,[\"id\"]],[23,9,[\"draftRegistration\",\"datetimeInitiated\"]],[23,9,[\"draftRegistration\",\"datetimeUpdated\"]],\" \"]]],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/schema-block-renderer/editable/text/template.hbs"
    }
  });

  _exports.default = _default;
});