define("osf-components/components/registries/schema-block-renderer/section-heading/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "SectionHeading": "_SectionHeading_8542aa _Heading_t890a9",
    "HelpText": "_HelpText_8542aa _Element_t890a9",
    "HelpTextLine": "_HelpTextLine_8542aa"
  };
  _exports.default = _default;
});