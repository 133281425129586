define("osf-components/components/registries/schema-block-renderer/editable/rdm/single-select-pulldown-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bE5E8S5U",
    "block": "{\"symbols\":[\"option\",\"@changeset\",\"@disabled\",\"@shouldShowMessages\",\"&attrs\",\"@uniqueID\",\"@schemaBlock\"],\"statements\":[[5,\"registries/schema-block-renderer/editable/base\",[],[[\"@helpText\"],[[23,0,[\"schemaBlock\",\"helpText\"]]]],{\"statements\":[[0,\"\\n    \"],[5,\"form-controls\",[[13,5]],[[\"@changeset\",\"@disabled\",\"@shouldShowMessages\"],[[23,2,[]],[23,3,[]],[23,4,[]]]],{\"statements\":[[0,\"\\n        \"],[5,\"power-select\",[[12,\"data-test-e-rad-award-number-input\",\"\"]],[[\"@options\",\"@onchange\",\"@oninput\",\"@searchEnabled\",\"@selected\",\"@uniqueID\"],[[23,0,[\"optionBlockValues\"]],[28,\"action\",[[23,0,[]],[23,0,[\"onChange\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"onInputSearch\"]]],null],true,[28,\"or\",[[28,\"get\",[[23,2,[]],[23,0,[\"valuePath\"]]],null],null],null],[23,6,[]]]],{\"statements\":[[0,\"\\n            \"],[1,[23,1,[]],false],[0,\"\\n        \"]],\"parameters\":[1]}],[0,\"\\n        \"],[5,\"validation-errors\",[[12,\"class\",[29,[[28,\"local-class\",[\"ValidationErrors\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/editable/rdm/single-select-pulldown-input/styles\"]]]]]]],[[\"@changeset\",\"@key\"],[[23,2,[]],[23,7,[\"registrationResponseKey\"]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/schema-block-renderer/editable/rdm/single-select-pulldown-input/template.hbs"
    }
  });

  _exports.default = _default;
});