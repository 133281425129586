define("osf-components/components/registries/schema-block-renderer/editable/rdm/ad-metadata-input/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "file-metadata-input-container": "_file-metadata-input-container_1c2obr",
    "file-metadata-input-files": "_file-metadata-input-files_1c2obr",
    "file-metadata-path": "_file-metadata-path_1c2obr",
    "file-metadata-title": "_file-metadata-title_1c2obr",
    "file-metadata-manager": "_file-metadata-manager_1c2obr",
    "file-metadata-input-edit-button": "_file-metadata-input-edit-button_1c2obr",
    "file-metadata-input-buttons": "_file-metadata-input-buttons_1c2obr"
  };
  _exports.default = _default;
});