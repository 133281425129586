define("osf-components/components/registries/schema-block-renderer/page-heading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wt7HGidi",
    "block": "{\"symbols\":[\"line\",\"part\",\"@schemaBlock\",\"&attrs\"],\"statements\":[[4,\"if\",[[28,\"or\",[[28,\"not\",[[23,3,[\"concealmentPageNavigator\"]]],null],[28,\"eq\",[[23,3,[\"concealmentPageNavigator\"]],[27]],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"h2\",false],[12,\"class\",[29,[[28,\"local-class\",[\"PageHeading\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/page-heading/styles\"]]]]]],[12,\"data-test-page-heading\",\"\"],[12,\"id\",[23,3,[\"elementId\"]]],[13,4],[8],[0,\"\\n        \"],[1,[23,0,[\"localizedDisplayText\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"isEditableForm\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"PageHeading_helper\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/page-heading/styles\"]]]]]],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"localizedHelpText\"]]],null,{\"statements\":[[0,\"            \"],[7,\"p\",true],[11,\"class\",[29,[[28,\"local-class\",[\"PageHeading_helper\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/page-heading/styles\"]]]]]],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,2,[\"type\"]],\"link\"],null]],null,{\"statements\":[[0,\"                        \"],[7,\"a\",true],[11,\"href\",[23,2,[\"content\"]]],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener noreferrer\"],[8],[0,\"\\n                            \"],[1,[23,2,[\"content\"]],false],[0,\"\\n                        \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                        \"],[1,[23,2,[\"content\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[2]},null],[0,\"            \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/schema-block-renderer/page-heading/template.hbs"
    }
  });

  _exports.default = _default;
});