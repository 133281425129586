define("osf-components/components/registries/schema-block-renderer/read-only/rdm/ad-metadata-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SfK7Kn8Y",
    "block": "{\"symbols\":[\"fileEntry\"],\"statements\":[[5,\"registries/schema-block-renderer/editable/base\",[],[[\"@helpText\"],[[23,0,[\"schemaBlock\",\"helpText\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"fileEntries\"]]],null,{\"statements\":[[0,\"        \"],[7,\"table\",true],[11,\"class\",[29,[\"table table-responsive \",[28,\"local-class\",[\"file-metadata-input-files\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/read-only/rdm/ad-metadata-input/styles\"]]]]]],[8],[0,\"\\n            \"],[7,\"thead\",true],[8],[0,\"\\n                \"],[7,\"tr\",true],[8],[0,\"\\n                    \"],[7,\"th\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n                        \"],[1,[28,\"t\",[\"metadata.file-metadata-input.columns.path\"],null],false],[0,\"\\n                    \"],[9],[0,\"\\n                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"fileEntries\"]]],null,{\"statements\":[[0,\"                    \"],[7,\"tr\",true],[8],[0,\"\\n                        \"],[7,\"td\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n                            \"],[1,[23,1,[\"path\"]],false],[0,\"\\n                        \"],[9],[0,\"\\n                    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[5,\"loading-indicator\",[],[[\"@dark\"],[true]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/schema-block-renderer/read-only/rdm/ad-metadata-input/template.hbs"
    }
  });

  _exports.default = _default;
});