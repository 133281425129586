define("osf-components/components/registries/schema-block-renderer/editable/files/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3tIX1RLN",
    "block": "{\"symbols\":[\"@node\",\"@schemaBlock\",\"@changeset\",\"&attrs\"],\"statements\":[[4,\"if\",[[28,\"not\",[[23,2,[\"sentence\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[11,\"class\",[29,[[28,\"local-class\",[\"FilesInstructions\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/editable/files/styles\"]]]]]],[10,\"data-test-files-instructions\",\"\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"osf-components.registries.schema-block-renderer/editable/files.instructions\"],[[\"projectOrComponent\",\"nodeUrl\",\"htmlSafe\"],[[28,\"if\",[[23,1,[\"isRoot\"]],\"project\",\"component\"],null],[23,0,[\"nodeUrl\"]],true]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[5,\"files/selected-list\",[[12,\"class\",[29,[[28,\"local-class\",[\"SelectedList\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/editable/files/styles\"]]]]]]],[[\"@selectedFiles\",\"@unselect\",\"@node\"],[[23,0,[\"selectedFiles\"]],[28,\"action\",[[23,0,[]],[23,0,[\"unselect\"]]],null],[23,1,[]]]]],[0,\"\\n\"],[5,\"validation-errors\",[[12,\"class\",[29,[[28,\"local-class\",[\"ValidationErrors\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/editable/files/styles\"]]]]]]],[[\"@changeset\",\"@key\"],[[23,3,[]],[23,2,[\"registrationResponseKey\"]]]]],[0,\"\\n\"],[5,\"files/widget\",[[12,\"class\",[29,[[28,\"local-class\",[\"FilesWidget\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/editable/files/styles\"]]]]]],[12,\"data-test-editable-file-widget\",\"\"],[13,4]],[[\"@node\",\"@onSelectFile\",\"@onAddFile\"],[[23,1,[]],[28,\"action\",[[23,0,[]],[23,0,[\"onSelectFile\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"onAddFile\"]]],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/schema-block-renderer/editable/files/template.hbs"
    }
  });

  _exports.default = _default;
});