define("osf-components/components/registries/schema-block-renderer/section-heading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p6nck7N7",
    "block": "{\"symbols\":[\"@schemaBlock\",\"&attrs\"],\"statements\":[[7,\"h3\",false],[12,\"class\",[29,[[28,\"local-class\",[\"SectionHeading\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/section-heading/styles\"]]]]]],[12,\"data-test-section-heading\",\"\"],[12,\"id\",[23,1,[\"elementId\"]]],[13,2],[8],[0,\"\\n    \"],[1,[23,0,[\"localizedDisplayText\"]],false],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"HelpText\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/section-heading/styles\"]]]]]],[8],[0,\"\\n        \"],[7,\"p\",true],[11,\"class\",[29,[[28,\"local-class\",[\"HelpTextLine\"],[[\"from\"],[\"osf-components/components/registries/schema-block-renderer/section-heading/styles\"]]]]]],[8],[1,[23,0,[\"schemaBlock\",\"helpText\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isEditableForm\"]]],null,{\"statements\":[[0,\"            \"],[5,\"registries/schema-block-renderer/helper-text-icon\",[],[[\"@helpText\"],[[23,0,[\"localizedHelpText\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/registries/schema-block-renderer/section-heading/template.hbs"
    }
  });

  _exports.default = _default;
});