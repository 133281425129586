define("osf-components/components/validated-input/date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WvW0OoH0",
    "block": "{\"symbols\":[\"inputElementId\",\"@minDate\",\"@maxDate\",\"@disabled\",\"@autoValue\"],\"statements\":[[4,\"validated-input/x-input-wrapper\",null,[[\"model\",\"changeset\",\"errors\",\"label\",\"valuePath\",\"validationStatus\"],[[23,0,[\"model\"]],[23,0,[\"changeset\"]],[23,0,[\"errors\"]],[23,0,[\"label\"]],[23,0,[\"valuePath\"]],[23,0,[\"validationStatus\"]]]],{\"statements\":[[0,\"    \"],[5,\"ember-flatpickr\",[[12,\"class\",\"form-control\"],[12,\"placeholder\",[23,0,[\"placeholder\"]]],[12,\"id\",[23,1,[]]],[12,\"aria-label\",[23,0,[\"ariaLabel\"]]]],[[\"@onChange\",\"@minDate\",\"@maxDate\",\"@disabled\",\"@value\"],[[28,\"action\",[[23,0,[]],[23,0,[\"onChange\"]]],null],[23,2,[]],[23,3,[]],[23,4,[]],[23,0,[\"value\"]]]]],[0,\"   \\n\"],[4,\"if\",[[23,5,[]]],null,{\"statements\":[[0,\"        \"],[5,\"osf-button\",[],[[\"@type\",\"@onClick\"],[\"success\",[28,\"action\",[[23,0,[]],[23,0,[\"getDate\"]]],null]]],{\"statements\":[[0,\"\\n            \"],[1,[28,\"t\",[\"registries.drafts.draft.form.auto_button_label\"],null],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "osf-components/components/validated-input/date/template.hbs"
    }
  });

  _exports.default = _default;
});